<template>
  <div v-loading="loading">
    <div class="refund-top" style="width:100%">
      <div class="refund-number">
        <div >
          <span class="lable">退款编号：</span>
          <span class="text">{{ ruleForm.SupportNo }}</span>
        </div>
        <div class="refund-status" style="margin-left:20px">
          <span class="lable">退款状态：</span>
          <span v-if="ruleForm.SupportState == 1" class="text"
            >退款申请待商家处理</span
          >
          <span v-if="ruleForm.SupportState == 2" class="text"
            >商家不同意退款申请，待买家处理</span
          >
          <span v-if="ruleForm.SupportState == 3" class="text"
            >商家已同意退货申请，待买家寄回商品</span
          >
          <span v-if="ruleForm.SupportState == 4" class="text"
            >买家已寄回商品，待商家确认收货</span
          >
          <span v-if="ruleForm.SupportState == 5" class="text"
            >商家拒绝收货，待买家处理</span
          >
          <span v-if="ruleForm.SupportState == 6" class="text">退款成功</span>
          <span v-if="ruleForm.SupportState == 7" class="text">退款关闭</span>
        </div>
        <div class="refund-number" style="margin-left:20px" v-if="ruleForm.SupportState == 6">
          <span class="lable">钱款去向:</span>
          <span class="text">{{ruleForm.IsVirtualRefundValue }} </span>
        </div>
        <div class="refund-number" style="margin-left:20px" v-if="ruleForm.SupportState == 6">
          <span class="lable">钱款退回进度:</span>
          <span class="text">{{ruleForm.RefundStateValue}}</span>
        </div>
        <el-button type="text" style="margin-left:20px" v-if="ruleForm.RefundState == 3" @click="againrefund">重新发起退款</el-button>
      </div>
      <!-- 商家已同意退货申请，待买家寄回商品 -->
      <div class="refund-remark" v-if="ruleForm.SupportState == 3">
        <span>买家寄回地址：</span>
        <span style="margin-left:5px;">{{ ruleForm.ReceiveName }}</span>
        <span style="margin-left:5px;">{{ ruleForm.ReceivePhone }}</span>
        <span style="margin-left:5px;"
          >{{ ruleForm.ReceiveProvince }}{{ ruleForm.ReceiveCity
          }}{{ ruleForm.ReceiveProvince }}{{ ruleForm.ReceiveArea }}</span
        >
        <span>·</span>
        <span>{{ ruleForm.ReceiveAddress }}</span>
      </div>
      <!-- 商家不同意退款申请，待买家处理 -->
      <div class="refund-remark" v-if="ruleForm.SupportState == 2">
        <span>拒绝理由：</span>
        <span style="margin-left:5px;">{{
          ruleForm.FailedPassReason || "--"
        }}</span>
      </div>
      <!-- 买家已寄回商品，待商家确认收货/退款成功 -->
      <!-- &&(ruleForm.ReturnExpressCompany||ruleForm.ReturnExpressNo) -->
      <div
        class="refund-remark"
        style=""
        v-if="
          (ruleForm.SupportState == 4 || ruleForm.SupportState == 6) &&
            ruleForm.ReturnExpressCompany
        "
      >
        <div style="display:inline-block;">
          <span>物流公司：</span>
          <span style="margin-left:5px;">{{
            ruleForm.ReturnExpressCompany
          }}</span>
        </div>
        <div style="display:inline-block;">
          <span>物流单号：</span>
          <span style="margin-left:5px;">{{ ruleForm.ReturnExpressNo }}</span>
          <span
            style="margin-left:5px;color:#409EFF;cursor:pointer"
            v-if="ruleForm.ReturnExpressNo != ''"
            @click="lookLogest(ruleForm.SupportNo)"
            >查看物流</span
          >
        </div>
        <div
          v-if="returnExpressImgUrlsLength"
          style="width:100%;display:flex;flex-direction: row;line-height:14px;margin:10px 0;"
        >
          <div style="width:73px;flex:0 0 auto;">物流凭证：</div>
          <div>
            <img
              class="refund-img"
              @click="
                handleChangeImg(ruleForm.ReturnExpressImgUrlsComplete, index)
              "
              v-for="(item, index) in ruleForm.ReturnExpressImgUrlsComplete"
              :key="index"
              :src="item"
            />
          </div>
        </div>
      </div>
      <!-- 商家拒绝收货，待买家处理 -->
      <div class="refund-remark-two" v-if="ruleForm.SupportState == 5">
        <div style="" v-if="ruleForm.ReturnExpressCompany">
          <div style="width:50%;display:inline-block;">
            <span>物流公司：</span>
            <span style="margin-left:5px;">{{
              ruleForm.ReturnExpressCompany
            }}</span>
          </div>
          <div style="width:50%;display:inline-block;">
            <span>物流单号：</span>
            <span style="margin-left:5px;">{{ ruleForm.ReturnExpressNo }}</span>
            <span
              style="margin-left:5px;color:#409EFF;cursor:pointer"
              v-if="ruleForm.ReturnExpressNo != ''"
              @click="lookLogest(ruleForm.SupportNo)"
              >查看物流</span
            >
          </div>
          <div
            v-if="returnExpressImgUrlsLength"
            style="width:100%;display:flex;flex-direction: row;line-height:14px;margin:10px 0;"
          >
            <div style="width:73px;flex:0 0 auto;">物流凭证：</div>
            <div>
              <img
                class="refund-img"
                @click="
                  handleChangeImg(ruleForm.ReturnExpressImgUrlsComplete, index)
                "
                v-for="(item, index) in ruleForm.ReturnExpressImgUrlsComplete"
                :key="index"
                :src="item"
              />
            </div>
          </div>
        </div>
        <div>
          <span>拒绝理由：</span>
          <div
            style="margin-left:5px;overflow:hidden;width:100%;display:inline-block;overflow-wrap: break-word;"
          >
            {{ ruleForm.FailedPassReason || "--" }}
          </div>
        </div>
      </div>
    </div>
    <div class="refund-message">
      <div class="refund-message-left">
        <div class="title">退款信息</div>
        <div class="item">
          <div class="lable">退款方式：</div>
          <div class="text">{{ ruleForm.SupportWayDisplay || "--" }}</div>
        </div>
        <div class="item">
          <div class="lable">退款类型：</div>
          <div class="text">{{ ruleForm.SupportTypeDisplay || "--" }}</div>
        </div>
        <div class="item" v-if="ruleForm.ReceiveState != 0">
          <div class="lable">货物状态：</div>
          <div class="text">{{ ruleForm.ReceiveStateDisplay || "--" }}</div>
        </div>
        <div class="item">
          <div class="lable">退款原因：</div>
          <div class="text">{{ ruleForm.RefundReason || "--" }}</div>
        </div>
        <div class="item">
          <div class="lable">退款说明：</div>
          <div class="text">{{ ruleForm.RefundDescribe || "--" }}</div>
        </div>
        <div class="item">
          <div class="lable">退款凭证：</div>
          <div class="text">
            <span v-if="!certificateLength">--</span>
            <img
              class="img"
              @click="handleChangeImg(ruleForm.ImgUrlsComplete, index)"
              v-for="(item, index) in ruleForm.ImgUrlsComplete"
              :key="index"
              :src="item"
            />
          </div>
        </div>
      </div>
      <div class="refund-message-right">
        <div class="title">原订单信息</div>
        <div class="item">
          <div class="lable">订单编号：</div>
          <div style="color:red" v-if="ruleForm.SendTypeDisplay">
            [{{ ruleForm.SendTypeDisplay }}]
          </div>
          <div class="text number" @click="lookOrderDetails(ruleForm.OrderNo)">
            {{ ruleForm.OrderNo }}
          </div>
        </div>
        <div class="item" v-if="ishowIsOpenSupplierProduct">
          <div class="lable">
            分销采购单：{{ ruleForm.SupplierMallId > 0 ? "是" : "否" }}
          </div>
        </div>
        <div class="item">
          <div class="lable">支付时间：</div>
          <div class="text">{{ ruleForm.PayTime }}</div>
        </div>
        <div class="item">
          <div class="lable">支付方式：</div>
          <div class="text">{{ ruleForm.PayByDisplay }}</div>
        </div>
      </div>
    </div>

    <div class="refund-table">
      <el-table
        :data="tableProList"
        v-if="tableProList.length"
        style="width: 100%"
      >
        <el-table-column label="商品" width="300px">
          <template slot-scope="scope">
            <div class="dialog-name-content">
              <img :src="scope.row.ImgUrlComplete" />
              <div class="right">
                <div class="name">{{ scope.row.ProductName }}</div>
                <span
                  style="color:#999;font-size:12px;"
                  v-if="ruleForm.SupplierMallId == 0"
                  >{{ scope.row.ProductNo }}</span
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            <span v-if="scope.row.SpecValue == '' && scope.row.SpecValue2 == ''"
              >默认规格</span
            >
            <div v-else>
              <span>{{ scope.row.SpecValue }}</span>
              <span v-if="scope.row.SpecValue != ''">;</span>
              <span>{{ scope.row.SpecValue2 }}</span>
            </div>
            <div
              v-if="ruleForm.SupplierMallId == 0"
              style="color:#999;font-size:12px;"
            >
              {{ scope.row.BarCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ProductCount" label="退款数量"></el-table-column>
        <el-table-column prop="RefundMoney" label="退款金额"></el-table-column>
        <el-table-column
          prop="SendStateDisplay"
          label="发货状态"
        ></el-table-column>
      </el-table>
    </div>

    <div class="refund-record">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="协商记录" name="1"></el-tab-pane>
        <el-tab-pane label="钱款退回进度" name="2" v-if="ruleForm.SupportState == 6"></el-tab-pane>
      </el-tabs>
      <div class="refund-timeline" v-if="activeName=='1'">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in ruleForm.RecordList"
            :key="index"
            :timestamp="item.AddTime"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.OperationType == 1">商家</h4>
              <h4 v-if="item.OperationType == 2">买家</h4>
              <h4 v-if="item.OperationType == 3">系统</h4>
              <p v-if="item.OperationType == 1">
                <span v-if="item.OperatorName && item.OperatorName != ''"
                  >操作人：{{ item.OperatorName }}</span
                >
                <span v-if="item.OperatorPhone && item.OperatorPhone != ''"
                  >({{ item.OperatorPhone }})</span
                >
              </p>
              <p style="word-wrap:break-word;">{{ item.Remark }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="refund-timeline" v-if="activeName=='2'">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in ruleForm.OrderSupportRefundRecordList"
            :key="index"
            :timestamp="item.AddTime"
            placement="top"
          >
            <el-card>
              <h4>{{item.TypeValue}}</h4>
              <p style="word-wrap:break-word;">{{ item.Remark }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <!-- 查看物流 -->
    <el-dialog :visible.sync="logistdialogVisible" title="物流详情">
      <el-tabs type="border-card">
        <div class="express-info">
          <div class="info-item">
            <label class="label">物流公司</label>
            <span>{{ expressInfo.ExpressValue }}</span>
          </div>
          <div class="info-item">
            <label class="label">物流单号</label>
            <span>{{ expressInfo.ExpressNo }}</span>
          </div>
          <div class="info-item">
            <label class="label">物流跟踪</label>
            <div
              class="item-detail"
              v-if="
                expressInfo.ExpressTraceList &&
                  expressInfo.ExpressTraceList.length
              "
            >
              <div
                class="m-step"
                v-for="(j, index) in expressInfo.ExpressTraceList"
                :key="index"
                v-if="j && j.AcceptTime"
              >
                <div class="head">
                  <div class="line"></div>
                  <div class="icon"></div>
                </div>
                <div class="main">
                  <div class="card">
                    <div class="title">{{ j.AcceptTime }}</div>
                    <div class="content">{{ j.AcceptStation }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="margin-top: 6px;">暂无信息</div>
            </div>
            <div v-else style="margin-top:23px">暂无跟踪到物流信息</div>
          </div>
        </div>
      </el-tabs>
    </el-dialog>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="imgZoomShow">
      <el-carousel
        v-if="imgZoomShow"
        height="600px"
        :initial-index="initialIndex"
        arrow="always"
        :autoplay="false"
      >
        <el-carousel-item v-for="item in imgList" :key="item">
          <img style="width:100%;height:100%;object-fit:contain;" :src="item" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import apiList from "@/api/other";
import config from "@/config/index";
export default {
  data() {
    return {
      activeName:'1',
      returnExpressImgUrlsLength: 0,
      imgZoomShow: false,
      initialIndex: 0,
      imgList: [],
      imgUrl: config.IMG_BASE,
      loading: false,
      certificateLength: 0,
      ruleForm: {
        // SupportState :1,
        // ReceiveName : '姓名',
        // ReceivePhone : 178580000,
        // ReceiveProvince : '地址省',
        // ReceiveCity : '地址市',
        // ReceiveArea : '地址区',
        // ReceiveAddress: '地址详细地址',
        // FailedPassReason:'拒绝理由拒绝理由拒绝理由拒绝理由拒绝理由拒绝理由拒绝理由拒绝理由拒绝理由'
      },
      tableProList: [],
      currentId: "",
      logistdialogVisible: false,
      expressInfo: {},
      ishowIsOpenSupplierProduct: false,
    };
  },
  created() {
    this.$store
      .dispatch("GetPcMallName", {})
      .then(() => {})
      .catch((e) => {});
    this.ishowIsOpenSupplierProduct =
      window.localStorage.getItem("IsOpenSupplierProduct") == "true"
        ? true
        : false;
    this.currentId = this.$route.query.id;
    this.getList();
  },
  methods: {
    async againrefund(){
      try{
        this.loading = true
        let res = await apiList.ordersupportrefundagain({
          SupportNo:this.currentId
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getList()
        }
      }finally{
        this.loading = false
      }
    },
    handleClick(e){
      // if(e.name == '1'){

      // }else {

      // }
    },
    //放大图片
    handleChangeImg(imgList, index) {
      this.imgList = imgList;
      this.initialIndex = index;
      this.imgZoomShow = true;
    },
    // 查看物流
    async lookLogest(SupportNo) {
      this.logistdialogVisible = true;
      try {
        let result = await apiList.ordersupportExpress({
          SupportNo: SupportNo,
        });
        this.expressInfo = result.Result;
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    // 查看订单详情
    lookOrderDetails(OrderNo) {
      this.$router.push({
        path: "/order/orderDetailpage",
        query: {
          Id: OrderNo ? OrderNo : 0,
        },
      });
    },
    async getList() {
      try {
        this.loading = true;
        let result = await apiList.ordersupportDetail({
          SupportNo: this.currentId,
        });
        this.ruleForm = result.Result;

        // console.log(this.ruleForm)
        this.returnExpressImgUrlsLength = this.ruleForm.ReturnExpressImgUrlsComplete.length;
        this.tableProList[0] = {
          ImgUrl: this.ruleForm.ImgUrl,
          ImgUrlComplete: this.ruleForm.ImgUrlComplete,
          ProductName: this.ruleForm.ProductName,
          SpecValue: this.ruleForm.SpecValue,
          SpecValue2: this.ruleForm.SpecValue2,
          ProductNo: this.ruleForm.ProductNo,
          BarCode: this.ruleForm.BarCode,
          ProductCount: this.ruleForm.ProductCount,
          RefundMoney: this.ruleForm.RefundMoney,
          SendStateDisplay: this.ruleForm.SendStateDisplay,
        };
        this.certificateLength = this.ruleForm.ImgUrls.length;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.refund-top {
  background-color: #fff;
  padding: 0 10px 0 10px;
  // overflow: hidden;
  // display: flex;
  // flex-direction: row;
  .refund-number {
    display: flex;
    flex-direction: row;
    line-height: 60px;
    color: #606266;
    .refund-status {
      // margin-left: 430px;
    }
    .lable {
      font-size: 14px;
      font-weight: bold;
    }
    .text {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .refund-remark {
    font-size: 14px;
    padding: 0 10px;
    width: auto;
    line-height: 50px;
    // height: 50px;
    border: 2px solid #409eff;
    background-color: #d9ecff;
    margin-bottom: 20px;
    .refund-img {
      border: 1px solid #ddd;
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 5px;
    }
  }
  .refund-remark-two {
    font-size: 14px;
    padding: 10px 10px;
    width: 100%;
    line-height: 30px;
    border: 2px solid #409eff;
    background-color: #d9ecff;
    margin-bottom: 20px;

    .refund-img {
      border: 1px solid #ddd;
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 5px;
    }
  }
}
.refund-message {
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  overflow: hidden;
  .title {
    font-weight: bold;
    margin-bottom: 15px;
  }
  .item {
    font-size: 14px;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
  }
  .text {
    width: 230px;
    overflow: hidden;
  }
  .img {
    border: 1px solid #ddd;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 5px;
  }
  .refund-message-left {
    width: 50%;
    float: left;
  }
  .refund-message-right {
    width: 50%;
    float: right;
    .number {
      color: #409eff;
      cursor: pointer;
    }
  }
}
.refund-table {
  margin-top: 10px;
  .dialog-name-content {
    display: flex;
    flex-direction: row;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 10px;
      border: 1px solid #ddd;
    }
    .name {
      font-size: 14px;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    span {
      font-size: 12px;
    }
  }
}
// .el-carousel__item.is-active{
//   text-align: center;
// }
.refund-record {
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  overflow: hidden;
  .title {
    font-weight: bold;
  }
  .refund-timeline {
    margin-top: 20px;
    ::v-deep .el-timeline {
      padding-left: 10px !important;
    }
  }
}
</style>
